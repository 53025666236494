const configuration = {
  // dev: {
  //     host: "https://mitservice-dev.app.dev.dal.pcf.syfbank.com",
  //     host1: "https://mppcore-dev.app.dev.dal.pcf.syfbank.com",
  //     dbuyTip: "https://detail.mysynchrony.com/dbuyTIP/",
  //     dbuyQa: "https://qbuy.syf.com"
  // },
  dev: {
    host: "https://dpdpone.syfpos.com/mitservice",
    host1: "https://dpdpone.syfpos.com/mppcore",
    dbuyTip: "https://pos.dbusinesscenter.synchronybusiness.com/dbuyTIP/",
    dbuyQa: "http://pos.dbuy.syf.com",
    applyHost: "https://pos.dapply.syf.com/eapply/eapply.action",
    preqApplyHost: "https://pos.dapply.syf.com/eapply/eapply.action",
    syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
    mppCheckout:
      "https://dpdpone.syfpos.com/mppcore/mppCheckOutNew",
    widgeturl: "https://dwidgets.syfpos.com",
    payLaterUrl:"https://uwww.setpay.com/datashare",
    payLaterLookUpUrl:"https://uwww.setpay.com/lookup/datashare",

  },
   dev2: {
    host: "https://d2pdpone.syfpos.com/mitservice",
    host1: "https://d2pdpone.syfpos.com/mppcore",
    dbuyTip: "https://pos.dbusinesscenter.synchronybusiness.com/dbuyTIP/",
    dbuyQa: "https://pos.dbuy.syf.com",
    applyHost: "https://pos.dapply.syf.com/eapply/eapply.action",
    preqApplyHost: "https://pos.dapply.syf.com/eapply/eapply.action",
    syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
    mppCheckout:
      "https://d2pdpone.syfpos.com/mppcore/mppcheckout",
    widgeturl: "https://dwidgets.syfpos.com",
    payLaterUrl:"https://uwww.setpay.com/datashare",
    payLaterLookUpUrl:"https://uwww.setpay.com/lookup/datashare",

  },
  dev1: {
    host: "https://mitservice-dev.app.dev1.dal.pcf.syfbank.com/mitservice",
    host1: "https://mppcore-dev.app.dev1.dal.pcf.syfbank.com/mppcore",
    dbuyTip: "https://pos.dbusinesscenter.synchronybusiness.com/dbuyTIP/",
    dbuyQa: "https://pos.dbuy.syf.com",
    applyHost: "https://pos.dapply.syf.com/eapply/eapply.action",
    preqApplyHost: "https://pos.dapply.syf.com/eapply/eapply.action",
    syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
    mppCheckout:
      "https://mppcore-dev.app.dev1.dal.pcf.syfbank.com/mppcore/mppcheckout",
    widgeturl: "https://widget-domain-service-dev.app.dev.usw2.pcf.syfbank.com",
    payLaterUrl:"https://uwww.setpay.com/datashare",
    payLaterLookUpUrl:"https://uwww.setpay.com/lookup/datashare",
  },
  phx: {
    host: "https://mitservice-dev.app.phx.phx.pcf.syfbank.com/mitservice",
    host1: "https://mppcore-dev.app.dev.phx.pcf.syfbank.com/mppcore",
    dbuyTip: "https://pos.dbusinesscenter.synchronybusiness.com/dbuyTIP/",
    dbuyQa: "https://pos.dbuy.syf.com",
    applyHost: "https://pos.dapply.syf.com/eapply/eapply.action",
    preqApplyHost: "https://pos.dapply.syf.com/eapply/eapply.action",
    syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
    mppCheckout:
      "https://mppcore-dev.app.dev.phx.pcf.syfbank.com/mppcore/mppcheckout",
    widgeturl: "https://dwidgets.syfpos.com",
    payLaterUrl:"https://uwww.setpay.com/datashare",
    payLaterLookUpUrl:"https://uwww.setpay.com/lookup/datashare",
  },
  local: {
    widgeturl: "https://dwidgets.syfpos.com",
    host: "http://localhost:8080",
    host1: "http://localhost:8090/mppApp",
    dbuyTip: "https://detail.mysynchrony.com/dbuyTIP/",
    dbuyQa: "https://qbuy.syf.com",
    applyHost: "https://detail.mysynchrony.com/eapply/eapply.action",
    preqApplyHost: "https://pos.dapply.syf.com/eapply/eapply.action",
    syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
    mppCheckout: "http://localhost:8090/mppApp/mppcheckout",
    payLaterUrl:"https://uwww.setpay.com/datashare",
    payLaterLookUpUrl:"https://uwww.setpay.com/lookup/datashare",
  },
  qa: {
    host: "https://qpdpone.syfpos.com/mitservice",
    host1: "https://qpdpone.syfpos.com/mppcore",
    dbuyTip: "https://pos.qbusinesscenter.synchronybusiness.com/dbuyTIP/",
    dbuyQa: "https://pos.qbuy.syf.com",
    applyHost: "https://pos.qapply.syf.com/eapply/eapply.action",
    preqApplyHost: "https://pos.qapply.syf.com/eapply/eapply.action",
    syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
    mppCheckout: "https://qpdpone.syfpos.com/mppcore/mppcheckout",
    widgeturl: "https://qwidgets.syfpos.com",
    payLaterUrl:"https://uwww.setpay.com/datashare",
    payLaterLookUpUrl:"https://uwww.setpay.com/lookup/datashare",
  },
  uat: {
    host: "https://updpone.syfpos.com/mitservice",
    host1: "https://updpone.syfpos.com/mppcore",
    dbuyTip: "https://pos.ubusinesscenter.synchronybusiness.com/dbuyTIP/",
    dbuyQa: "https://pos.ubuy.syf.com",
    applyHost: "https://pos.uapply.syf.com/eapply/eapply.action",
    preqApplyHost: "https://pos.uapply.syf.com/eapply/eapply.action",
    syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
    mppCheckout: "https://updpone.syfpos.com/mppcore/mppcheckout",
    widgeturl: "https://uwidgets.syfpos.com",
    payLaterUrl:"https://uwww.setpay.com/datashare",
    payLaterLookUpUrl:"https://uwww.setpay.com/lookup/datashare",
  },
  stg: {
    host: "https://spdpone.syfpos.com/mitservice",
    host1: "https://spdpone.syfpos.com/mppcore",
    dbuyTip: "https://pos.sbusinesscenter.synchronybusiness.com/dbuyTIP/",
    dbuyQa: "https://pos.sbuy.syf.com",
    applyHost: "https://pos.sapply.syf.com/eapply/eapply.action",
    preqApplyHost: "https://pos.sapply.syf.com/eapply/eapply.action",
    syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
    mppCheckout: "https://spdpone.syfpos.com/mppcore/mppcheckout",
    widgeturl: "https://swidgets.syfpos.com",
    payLaterUrl:"https://swww.setpay.com/datashare",
    payLaterLookUpUrl:"https://swww.setpay.com/lookup/datashare",
  },
  prf: {
    host: "https://ppdpone.syfpos.com/mitservice",
    host1: "https://ppdpone.syfpos.com/mppcore",
    dbuyTip: "https://pos.pbusinesscenter.synchronybusiness.com/dbuyTIP/",
    dbuyQa: "https://pos.pbuy.syf.com",
    applyHost: "https://pos.dapply.syf.com/eapply/eapply.action",
    preqApplyHost: "https://pos.dapply.syf.com/eapply/eapply.action",
    syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
    mppCheckout: "https://ppdpone.syfpos.com/mppcore/mppcheckout",
    widgeturl: "https://pwidgets.syfpos.com",
    payLaterUrl:"https://uwww.setpay.com/datashare",
    payLaterLookUpUrl:"https://uwww.setpay.com/lookup/datashare",
  },
  prod: {
    host: "https://pdpone.syfpayments.com/mitservice",
    host1: "https://pdpone.syfpayments.com/mppcore",
    dbuyQa: "https://buy.syf.com",
    applyHost: "https://etail.mysynchrony.com/eapply/eapply.action",
    preqApplyHost: "https://etail.mysynchrony.com/eapply/eapply.action",
    syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
    mppCheckout: "https://pdpone.syfpayments.com/mppcore/mppcheckout",
    widgeturl: "https://widgets.syfpayments.com",
    payLaterUrl:"https://www.setpay.com/datashare",
    payLaterLookUpUrl:"https://www.setpay.com/lookup/datashare",
  },


  use1: {
    //for temp purpose using dev env urls for use1
   host: "https://dpdpone.syfpos.com/mitservice",
     host1: "https://dpdpone.syfpos.com/mppcore",
     dbuyTip: "https://pos.dbusinesscenter.synchronybusiness.com/dbuyTIP/",
     dbuyQa: "https://pos.dbuy.syf.com",
     applyHost: "https://pos.dapply.syf.com/eapply/eapply.action",
     preqApplyHost: "https://pos.dapply.syf.com/eapply/eapply.action",
     syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
     mppCheckout:
       "https://dpdpone.syfpos.com/mppcore/mppcheckout",
     widgeturl: "https://dwidgets.syfpos.com",
     payLaterUrl:"https://uwww.setpay.com/datashare",
     payLaterLookUpUrl:"https://uwww.setpay.com/lookup/datashare",
       
  },
usw2: {
    //for temp purpose using dev env urls for usw2
     widgeturl: "https://dwidgets.syfpos.com",
     host: "https://dpdpone.syfpos.com/mitservice",
       host1: "https://mppcore-dev.app.dev.usw2.pcf.syfbank.com/mppcore",
       dbuyTip: "https://pos.dbusinesscenter.synchronybusiness.com/dbuyTIP/",
       dbuyQa: "https://pos.dbuy.syf.com",
       applyHost: "https://pos.dapply.syf.com/eapply/eapply.action",
       preqApplyHost: "https://pos.dapply.syf.com/eapply/eapply.action",
       syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
       mppCheckout:
         "https://dpdpone.syfpos.com/mppcore/mppcheckout",
       payLaterUrl:"https://uwww.setpay.com/datashare",
       payLaterLookUpUrl:"https://uwww.setpay.com/lookup/datashare",
 },

};
export default configuration;
