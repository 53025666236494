import axios from "axios";
import configuration from "../configuration/configuration";

class Services {
  fetchDBUYInfoFromServer(tokenId) {
    return axios
      .get(
        configuration[window._syfMpp_.SYF_MPP_ENV].host1 +
        "/mpp-data/" +
        tokenId
      )
      .catch((error) => {
        return error;
      });
  }

  fetchStatus(syfToken, partnerId) {
    return axios
      .get(
        configuration[window._syfMpp_.SYF_MPP_ENV].host1 +
        "/fetchStatus?syfId=" +
        syfToken +
        "&partnerId=" +
        partnerId
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  }

  fetchOfferDetails(merchantNumber, amount) {
    return axios
      .post(
        "https://api-offer-service.app.dev.dal.pcf.syfbank.com/offers/lookup",
        {
          merchantNumber,
          amount,
        },
        {
          headers: {
            "X-SYF-Request-channelId": "DD",
            "X-SYF-Request-trackingId": "33456789123",
          },
        }
      )
      .then((response) => {
        return response.data.offerInfo;
      })
      .catch((err) => { });
  }

  fetchMerchantSpecificDetails(
    processInd,
    partnerId,
    amount,
    productCategoryNames,
    productCategoryNames1,
    productCategoryNames2,
    productCategoryNames3,
    transAmount1,
    transAmount2,
    transAmount3,
    syfToken,
    flowType,
    childSyfPartnerId,
    partnerCode,
    childSyfMerchantNumber,
    promoOverRideFlag,
    offerNumber1,
    offerNumber2,
    offerNumber3,
    tokenId,
    transactionToken,
    d2dFlow,
    merchantRefererUrl,
    partnerProfileCode,
    siteCode,
    clientTransId,
    initialCallCompleted
  ) {
    return axios
      .post(
        configuration[window._syfMpp_.SYF_MPP_ENV].host1 +
        "/fetchMerchantSpecificInfo",
        {
          processInd,
          partnerId,
          transAmount1:
            transAmount1 == null
              ? amount?.replace(/^0+/, "")
              : transAmount1?.replace(/^0+/, ""),
          productCategoryNames: productCategoryNames,
          productCategoryNames1: productCategoryNames1,
          productCategoryNames2: productCategoryNames2,
          productCategoryNames3: productCategoryNames3,
          transAmount2: transAmount2,
          transAmount3: transAmount3,
          defaultPromoCode: "",
          syfToken,
          flowType,
          childSyfPartnerId,
          partnerCode,
          childSyfMerchantNumber,
          promoOverRideFlag,
          offerNumber1,
          offerNumber2,
          offerNumber3,
          tokenId,
          transactionToken,
          d2dFlow,
          merchantRefererUrl,
          partnerProfileCode,
          siteCode,
          clientTransId,
          initialCallCompleted
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw new Error();
      });
  }

  sendDataToCheckoutApi(
    partnerId,
    pcgc,
    amount,
    syfToken,
    flowType,
    clientName,
    productCategoryNames,
    setPayIn4Info,
    formPostData,
    merchantDomainURL,
    childSyfPartnerId,
    partnerCode,
    d2dFlow,
    childSyfMerchantNumber,
    empqFlag,
    fromEmail,
    merchantWebsiteURL,
    empqPaylaterMonthlyFlag,
    merchantRefererUrl,
    partnerProfileCode,
    softapplyEnabled,
    siteCode,
    clientTransId,
    mppFromAnyWhereFlag,
    actionType
  ) {
    return axios
      .post(
        configuration[window._syfMpp_.SYF_MPP_ENV].host1 +
        "/sendDataToCheckoutApi",
        {
          partnerId,
          transAmount1: amount,
          pcgc,
          syfToken,
          flowType,
          clientName,
          productCategoryNames,
          setPayIn4Info,
          formPostData,
          merchantDomainURL,
          childSyfPartnerId,
          partnerCode,
          d2dFlow,
          childSyfMerchantNumber,
          empqFlag,
          fromEmail,
          merchantWebsiteURL,
          empqPaylaterMonthlyFlag,
          merchantRefererUrl,
          partnerProfileCode,
          softapplyEnabled,
          siteCode,
          clientTransId,
          mppFromAnyWhereFlag,
          actionType
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw new Error();
      });
  }

  fetchBrandInfo(partnerId, requestFields) {
    return axios
      .post(
        configuration[window._syfMpp_.SYF_MPP_ENV].host1 +
        "/partnerconfiglookup",
        {
          partnerId,
          requestFields,
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw new Error();
      });
  }

  sendDataToDataShareApi(
    partnerId,
    pcgc,
    amount,
    syfToken,
    flowType,
    clientName,
    productCategoryNames,
    setPayIn4Info,
    formPostData,
    merchantDomainURL,
    childSyfPartnerId,
    partnerCode,
    d2dFlow,
    childSyfMerchantNumber,
    empqFlag,
    fromEmail,
    merchantWebsiteURL,
    empqPaylaterMonthlyFlag
  ) {
    return axios
      .post(
        configuration[window._syfMpp_.SYF_MPP_ENV].host1 +
        "/sendDataToDataShareApi",
        {
          partnerId,
          transAmount1: amount,
          pcgc,
          syfToken,
          flowType,
          clientName,
          productCategoryNames,
          setPayIn4Info,
          formPostData,
          merchantDomainURL,
          childSyfPartnerId,
          partnerCode,
          d2dFlow,
          childSyfMerchantNumber,
          empqFlag,
          fromEmail,
          merchantWebsiteURL,
          empqPaylaterMonthlyFlag
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw new Error();
      });
  }

}

const services = new Services();

export default services;
