import MPPContainer from '../mpp-container/container';
import SyfAjax from '../Util/SyfAjax';
import SyfUILogger from '../Util/SyfUILogger';
import { isEmpty, isNotEmpty, createInputField, createFormFieldsForJson, getGenericMPPModalTemplate, getSeamlessApplyBuyCookie } from '../Util/CommonUtils';
import config from '../configuration/configuration';
class DigitalBuyIntegration extends MPPContainer {
    constructor(analytics) {
        super(analytics);
        this.callbackMessage = null;
        this.sessionExpCallbackFlag = null;
        this.sessionExpFailUrl = null;
        this.dbpostBackUrl = null;
        this.dbPostBackType = null;
        this.b2CPostback = null;
        this.coreMerchantTokenId = null;
        this.syfUILogger = new SyfUILogger();
        this.registerDBUYEventListener(this);
        this.partnerId = null;
        this.productType=null;
        this.registerOfferEventListener();
    }
    dbuyEventListener = (event) => {
        if (typeof event.data == 'object') {
            let totalHeight = event.data.totalHeight;
            // this code has been added for displaying 100% in
            // case of mobile device
            let parentHeight = window.parent.innerHeight - 20;
            let parentWidth = window.parent.innerWidth;

            if (parentWidth <= 767
                && totalHeight < parentHeight) {
                totalHeight = parentHeight;
            }
            if (isNotEmpty(totalHeight)) {
                document.getElementsByClassName('syf-mpp-chng-height')[0].style.height = totalHeight + 'px';
            }
            this.callbackMessage = event.data.callbackMessage;
            if (event.data.coreMerchantTokenId) {
                this.coreMerchantTokenId = event.data.coreMerchantTokenId;
            }
            if (event.data.sessionExp) {
                this.sessionExpCallbackFlag = event.data.sessionExpCallbackFlag;
                this.sessionExpFailUrl = event.data.sessionExpFailUrl;
                this.dbPostBackType = event.data.dbPostBackType;
                this.dbpostBackUrl = event.data.dbpostBackUrl;
                this.b2CPostback = event.data.b2CPostback;
            }
        } else if (typeof event.data == 'string') {
            if (event.data == 'Close Model') {
                document.getElementsByClassName('syf-mpp-chng-height')[0].style.height = '530px';
                this.closeModalUI();
            } else if (event.data == 'stopSpinner') {
                document.getElementById('iframeMPPloading').style.display = 'none';
            } else if (event.data == 'closeModal') {
                this.closeModal();
            }
            else if (event.data == 'closeNotReq') {
                this.hideCloseButton();
            } else if (event.data == 'Return To Merchant Shipping') {
                this.closeModalUI();
            } else if (event.data == 'resetCloseBtnPosition') {
                this.resetCloseBtnPosition();
            } else if (event.data == 'Close' && null != document.getElementById("dBuyInstallmentFrame")) {
                this.closeModalUI();
                document.getElementById("dbuyform3").style.display = "block";
            }
            else if (event.data == 'redirectedToBnpl') {
                this.productType="BNPL";
            }
        }
    }
    registerDBUYEventListener() {
        window.addEventListener("message", this.dbuyEventListener, false);
    }
    unregisterDBUYEventListener() {
        window.removeEventListener("message", this.dbuyEventListener, false);
    }

    passJsonCallBackToMerchant = (callbackMessage) => {
        let syfAjax = new SyfAjax();
        syfAjax.get("parseDbuyJsonCallBack.do?callbackMessage="
            + callbackMessage, "", (err, data) => {
                if (err || data.status === 400 || data.status === 404) {
                    let logDesc = err;
                    console.error(logDesc);
                    if (data.status === 400 || data.status === 404) {
                        logDesc = "Failed 400 " + data.responseURL;
                    }
                    console.error(logDesc);
                    this.syfUILogger.sendLogs(logDesc);
                }
                syfAjax = null;
            });
    }
    hideCloseButton = () => {
        document.getElementById("syfMPPCrossButton")[0].style.display = "none";

    }
    authFailRedirect = (redirectUrl, callbackMessage) => {
        let form = document.createElement("form");
        form.setAttribute('id', "redirectForm");
        form.setAttribute('name', "redirectForm");
        form.setAttribute('method', "post");
        form.setAttribute('target', "_parent");
        form.setAttribute('action', redirectUrl);
        form.appendChild(createInputField("hidden", "callbackMessage",
            "callbackMessage", callbackMessage));
        document.body.appendChild(form);
        form.submit();
    }


    closeModal = () => {
        let syfAjax = new SyfAjax();
        let sessionExpDetails = "";
        sessionExpDetails = "sessionExpCallbackFlag="
            + ((isNotEmpty(this.sessionExpCallbackFlag)) ? this.sessionExpCallbackFlag
                : "");
        sessionExpDetails += "&sessionExpFailUrl="
            + ((isNotEmpty(this.sessionExpFailUrl)) ? this.sessionExpFailUrl : "");
        sessionExpDetails += "&dbpostBackUrl="
            + ((isNotEmpty(this.dbpostBackUrl)) ? this.dbpostBackUrl : "");
        sessionExpDetails += "&dbPostBackType="
            + ((isNotEmpty(this.dbPostBackType)) ? this.dbPostBackType : "");
        sessionExpDetails += "&b2CPostback="
            + ((isNotEmpty(this.b2CPostback)) ? this.b2CPostback : "");
        if (isEmpty(this.syfUILogger.tokenID)) {
            this.syfUILogger.tokenID = this.coreMerchantTokenId;
        }
        syfAjax.get(config[config.env].syfDomain + config[config.env].syfCloseButtonURL + this.syfUILogger.tokenID + "&requestOrigin=mpp&partnerId="+ this.partnerId+"&productType="+this.productType+ "&"
            + sessionExpDetails, "", (err, data) => {
                if (data) {
                    let response = JSON.parse(data.responseText);
                    this.callbackMessage = response.callbackMessage;
                    window.postMessage("Close Model", "*");
                    if (isNotEmpty(response.bnplTransactionCompleted) && response.bnplTransactionCompleted) {
                        this.destroySyfToken();
                    }
                    if (isNotEmpty(response.authenticationFailUrl)) {
                        this.authFailRedirect(response.authenticationFailUrl,
                            response.callbackMessage);
                    }
                    this.sessionExpCallbackFlag = null;
                    this.sessionExpFailUrl = null;
                    this.dbPostBackType = null;
                    this.dbpostBackUrl = null;
                    this.b2CPostback = null;
                } else if (err || data.status === 400) {
                    let logDesc = err;
                    window.postMessage("Close Model", "*");
                    if (null != data && data.status === 400) {
                        logDesc = "Failed 400 " + data.responseURL;
                    }
                    this.syfUILogger.sendLogs(logDesc);
                }
                syfAjax = null;
            });

            
        }
    closeModalUI = () => {
        this.unifyCloseModalEvent();
        this.closeModalWindow();
        this.unregisterDBUYEventListener();
        if (isNotEmpty(this.callbackMessage)) {
            try {
                if (document.getElementById("callbackMessage") !== null && document.getElementById("callbackMessage") !== null) {
                    document.getElementById("callbackMessage").innerHTML = this.callbackMessage;
                }
                this.passJsonCallBackToMerchant(this.callbackMessage);


            } catch (exception) {
                console.error(exception);
            }
        }
    }
    calldBuyProcess = (formObj, passedJsonObj) => {
        try {
            let processInd = 3;

            if (isNotEmpty(passedJsonObj)) {
                processInd = passedJsonObj.processInd;
            } else if (isNotEmpty(formObj.processInd)) {
                processInd = formObj.processInd.value;
            }
            this.openModalDialog(formObj, processInd, passedJsonObj);
        } catch (exception) {
            console.error(exception);
            this.syfUILogger.sendLogs(exception);
        }
    }
    openModalDialog = (formObj, processInd, passedJsonObj) => {
        try {
            formObj = createFormFieldsForJson(formObj, passedJsonObj);
            let url = config[config.env].syfMPPModalURL['checkout'];
            let syfToken;
            let partnerId;
            if (passedJsonObj != null) {
                partnerId = passedJsonObj.syfPartnerId;
            } else {
                partnerId = formObj.syfPartnerId.value;
            }
            this.partnerId = partnerId;
            getSeamlessApplyBuyCookie(partnerId, (syfToken) => {
                if (passedJsonObj != null) {
                    // url += "?tokenId=" + passedJsonObj.tokenId;
                    if(passedJsonObj.tokenId!="" && passedJsonObj.tokenId != undefined){
                        url += "?tokenId=" + passedJsonObj.tokenId;
                        this.syfUILogger.tokenID = passedJsonObj.tokenId;
                    }else if(passedJsonObj.transactionToken!="" && passedJsonObj.transactionToken!=undefined){
                        url += "?transactionToken=" + passedJsonObj.transactionToken;
                        this.syfUILogger.tokenID = passedJsonObj.transactionToken;
                    }else{
                        url += "?tokenId=" + passedJsonObj.tokenId;
                        this.syfUILogger.tokenID = passedJsonObj.tokenId;
                    }
                    url += "&partnerId=" + passedJsonObj.syfPartnerId;
                    url += "&processInd=" + passedJsonObj.processInd;
                    url += "&childMid=" + passedJsonObj.childMid;
                    // this.syfUILogger.tokenID = passedJsonObj.tokenId;
                } else {
                    if(formObj.tokenId && formObj.tokenId.value!="" && formObj.tokenId.value!=undefined){
                        this.syfUILogger.tokenID = formObj.tokenId.value;
                    }else if(formObj.transactionToken && formObj.transactionToken.value!="" && formObj.transactionToken.value!=undefined){
                        this.syfUILogger.tokenID = formObj.transactionToken.value;
                    }
                }
                super.tokenId = this.syfUILogger.tokenID;
                //cleanUpPreviousModal();
                let modalElementId = config[config.env].syfMppModalHolderId;

                //check if modal id does not exist
                if (!document.getElementById(modalElementId)) {
                    let modalDiv = document.createElement("div");
                    modalDiv.id = modalElementId;
                    document.body.appendChild(modalDiv);
                }
                if (document.getElementsByName("syfToken").length > 0) {
                    //  formObj.removeChild(document.getElementById("syfToken"));
                    document.getElementById("syfToken").remove();
                }
                formObj.appendChild(createInputField("hidden", "syfToken", "syfToken", syfToken));
                let modalTemplate = this.mppModalElementTemplate();
                this.loadModalInDOM(modalElementId, modalTemplate);
                this.modalActionOnDOMAttach(formObj, url);
                
                
                if(document.getElementsByClassName('syf-mpp-chng-height')){
                    if((window.parent.innerHeight - 20)<700){
                        document.getElementsByClassName('syf-mpp-chng-height')[0].style.height = window.innerHeight + 'px';
                    }else{
                      document.getElementsByClassName('syf-mpp-chng-height')[0].style.height = window.parent.innerHeight - 20+'px';
                    }
                }

            });

        } catch (exception) {
            console.error(exception);
            this.syfUILogger.sendLogs(exception);
        }
    }
    mppModalElementTemplate = () => {
        return getGenericMPPModalTemplate();
    }
    modalActionOnDOMAttach = (formObj, url) => {
        let form = formObj;
        if (isEmpty(form)) {
            form = document.getElementById('dbuyform3');
        }
        let destinationURLWithVisitorIDs = url;
        try {
            var visitor = window.Visitor.getInstance('22602B6956FAB4777F000101@AdobeOrg');
            destinationURLWithVisitorIDs = visitor.appendVisitorIDsTo(url);

        } catch (exception) {
            console.log("error creation analytics object");
        }
        form.target = "mppFrame";
        form.action = destinationURLWithVisitorIDs;
        form.method = "POST";
        form.submit();

    }


    resetCloseBtnPosition = () => {
        document.getElementById("syf-mpp-close-btn-new").setAttribute("style", "right: 0px;position: absolute;cursor: pointer;");
        document.getElementsByClassName("syf-mpp-chng-height")[0].classList.remove("syf-mpp-titleBar");
        document.getElementById("syfMPPModalCloseBtn").setAttribute("class", "syf-mpp-modal-XImage");
    }

    registerOfferEventListener() {
        window.onresize = function () {
            let parentHeight = window.parent.innerHeight - 20;
            let parentWidth = window.parent.innerWidth;
            if (parentWidth <= 767 && window.innerHeight < parentHeight) {
                window.innerHeight = parentHeight;
            }
            if((window.innerHeight-20)<700){
                   document.getElementsByClassName('syf-mpp-chng-height')[0].style.height = window.innerHeight + 'px';
            }else{
                document.getElementsByClassName('syf-mpp-chng-height')[0].style.height = window.innerHeight-20 + 'px';
            }
        }

    }



}
export default DigitalBuyIntegration;
